import { jsx, jsxs } from 'react/jsx-runtime';
import React, { useContext, useRef, useEffect, isValidElement, cloneElement, useReducer, useLayoutEffect } from 'react';

const StarportContext = React.createContext(
  null
);
StarportContext.displayName = "StarportContext";

var ActionType = /* @__PURE__ */ ((ActionType2) => {
  ActionType2["INITIAL"] = "INITIAL";
  ActionType2["UPDATE"] = "UPDATE";
  ActionType2["DISPOSE"] = "DISPOSE";
  return ActionType2;
})(ActionType || {});
const initial = (port) => {
  return { type: "INITIAL" /* INITIAL */, payload: { port } };
};
const update = (port, update2) => {
  return { type: "UPDATE" /* UPDATE */, payload: { port, update: update2 } };
};
const dispose = (port) => {
  return { type: "DISPOSE" /* DISPOSE */, payload: { port } };
};

const StarportCraft = (props) => {
  const ctx = useContext(StarportContext);
  if (!ctx) {
    throw new Error(
      "[React Starport] Failed to find the carrier, all Starport components must be wrapped in a <StarportCarrier> component."
    );
  }
  const { dispatch } = ctx;
  const elRef = useRef(null);
  const { instance } = props;
  useEffect(() => {
    if (instance.needRenderNext) {
      requestAnimationFrame(() => {
        const payload = { needRenderNext: false };
        if (instance.nextRect) {
          payload.rect = instance.nextRect;
          payload.nextRect = null;
        }
        if (instance.transitionStartProps) {
          payload.transitionStartProps = null;
        }
        dispatch(update(instance.port, payload));
      });
    }
  }, [instance.needRenderNext]);
  const { rect } = instance;
  const elapsed = Date.now() - instance.liftOffTime;
  const duration = Math.max(0, instance.options.duration - elapsed);
  const style = {
    position: "absolute",
    left: 0,
    top: 0,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
    padding: rect.padding,
    transform: `translate3d(${rect.left}px,${rect.top}px,0px)`,
    transitionProperty: "all",
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: instance.options.easing
  };
  const handleTransitionEnd = (event) => {
    if (event.target === elRef.current && event.propertyName === "transform") {
      dispatch(update(instance.port, { isLanded: true }));
    }
  };
  let element = instance.element;
  if (instance.transitionStartProps) {
    const transitionStartProps = { ...instance.transitionStartProps };
    if (isValidElement(element)) {
      for (const key of Object.keys(element.props)) {
        if (!Object.prototype.hasOwnProperty.call(transitionStartProps, key)) {
          transitionStartProps[key] = null;
        }
      }
    }
    element = cloneElement(
      instance.element,
      transitionStartProps
    );
  }
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: "starport-craft",
      style,
      onTransitionEnd: handleTransitionEnd,
      ref: elRef,
      children: element
    }
  );
};

const defaultOptions = {
  duration: 700,
  easing: "cubic-bezier(0.45, 0, 0.55, 1)"
};

const isServer = typeof window === "undefined";
const isClient = !isServer;
const root = isClient ? document.documentElement || document.body : void 0;
function isType(value, check) {
  return check(value);
}
function classNames(...classNames2) {
  return classNames2.reduce((all, current) => {
    if (all) {
      return current ? `${all} ${current}` : all;
    }
    return current;
  }, "");
}
function pickBy(object, predicate) {
  const picked = {};
  for (const [key, value] of Object.entries(object)) {
    if (predicate(key, value)) {
      picked[key] = value;
    }
  }
  return picked;
}

function getRectOfElement(el) {
  const { width, height, left, top } = el.getBoundingClientRect();
  const domStyle = window.getComputedStyle(el);
  const padding = domStyle.padding;
  const rect = {
    width,
    height,
    left,
    top: root.scrollTop + top,
    padding
  };
  return rect;
}
function createStarportInstance(port, options) {
  const el = null;
  const prevEl = null;
  const mergedOptions = {
    ...defaultOptions,
    ...options
  };
  return {
    prevEl,
    el,
    port,
    element: null,
    options: mergedOptions,
    rect: {
      width: 0,
      height: 0,
      left: 0,
      top: 0
    },
    nextRect: null,
    transitionStartProps: null,
    isLanded: true,
    needRenderNext: false,
    liftOffTime: 0
  };
}

const reducer = (state, action) => {
  if (isType(action, (action2) => typeof action2 === "function")) {
    return reducer(state, action(state));
  }
  const { type, payload } = action;
  switch (type) {
    case ActionType.INITIAL: {
      const { port } = payload;
      let instance = state[port];
      if (!instance) {
        instance = createStarportInstance(port, {});
      }
      return {
        ...state,
        [port]: instance
      };
    }
    case ActionType.UPDATE: {
      const { port, update } = payload;
      const instance = state[port];
      if (!instance) {
        return state;
      }
      return {
        ...state,
        [port]: { ...instance, ...update }
      };
    }
    case ActionType.DISPOSE: {
      const { port } = payload;
      const { [port]: deletedInstance, ...rest } = state;
      return deletedInstance?.el ? state : rest;
    }
    default:
      throw new Error(`React Starport: unsupported action [${type}]`);
  }
};

const useStore = () => {
  return useReducer(reducer, {});
};

const StarportContextProvider = (props) => {
  const [state, dispatch] = useStore();
  const context = {
    options: props.options,
    state,
    dispatch
  };
  return /* @__PURE__ */ jsx(StarportContext.Provider, { value: context, children: props.children });
};

const style = {
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 9999
};
const StarportCarrier = (props) => {
  const { children, ...options } = props;
  return /* @__PURE__ */ jsxs(StarportContextProvider, { options, children: [
    children,
    /* @__PURE__ */ jsx(StarportContext.Consumer, { children: (ctx) => {
      if (!ctx) {
        return null;
      }
      return /* @__PURE__ */ jsx("div", { style, children: Object.entries(ctx.state).filter(([, instance]) => !instance.isLanded).map(([port, instance]) => {
        return /* @__PURE__ */ jsx(StarportCraft, { instance }, port);
      }) });
    } })
  ] });
};

const Starport = (props) => {
  const { port, children } = props;
  const ctx = useContext(StarportContext);
  if (!ctx) {
    throw new Error(
      "[React Starport] Failed to find the carrier, all Starport components must be wrapped in a <StarportCarrier> component."
    );
  }
  const { state, dispatch } = ctx;
  const instance = state[port];
  const elRef = useRef(null);
  useLayoutEffect(() => {
    if (!instance) {
      dispatch(initial(port));
    }
    const el = elRef.current;
    if (!el) {
      return;
    }
    let payload = { el };
    if (instance) {
      if (instance.isLanded) {
        payload.liftOffTime = Date.now();
      }
      if (instance.prevEl !== elRef.current) {
        payload.isLanded = false;
        const nextRect = getRectOfElement(el);
        let transitionStartProps = null;
        if (isValidElement(instance.element) && isValidElement(children) && instance.element.type === children.type) {
          transitionStartProps = instance.element.props;
          payload.needRenderNext = true;
        }
        if (instance.isLanded) {
          payload = {
            ...payload,
            needRenderNext: true,
            nextRect
          };
        } else {
          payload = {
            ...payload,
            rect: nextRect
          };
        }
        payload = {
          ...payload,
          transitionStartProps
        };
      }
    }
    dispatch(update(port, payload));
    return () => {
      const el2 = elRef.current;
      if (!el2) {
        return;
      }
      const payload2 = {
        el: null,
        prevEl: el2
      };
      const rect = getRectOfElement(el2);
      dispatch((state2) => {
        const instance2 = state2[port];
        if (instance2.isLanded) {
          payload2.rect = rect;
        }
        return update(port, payload2);
      });
      requestAnimationFrame(() => {
        dispatch(dispose(port));
      });
    };
  }, []);
  useEffect(() => {
    dispatch(update(port, { element: children }));
  }, [children, dispatch, port]);
  useEffect(() => {
    const localOptions = {
      duration: props.duration,
      easing: props.easing
    };
    dispatch(
      update(port, {
        options: {
          ...defaultOptions,
          ...ctx.options,
          ...pickBy(localOptions, (_, v) => v !== void 0)
        }
      })
    );
  }, [ctx.options, dispatch, port, props.duration, props.easing]);
  return /* @__PURE__ */ jsx(
    "div",
    {
      ref: elRef,
      className: classNames(
        "starport",
        instance?.isLanded ? "starport--landed" : void 0,
        props.className
      ),
      style: props.style,
      children: instance?.isLanded ? props.children : null
    }
  );
};

export { Starport, StarportCarrier };
